<template>
  <div id="app">

    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>
<style>
@import 'normalize.css/normalize.css';
</style>

<style src="../src/assets/styles.css">

